import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";

export default () => (
  <Layout>
    <h1>music...</h1>
    <Link to="/">&larr; home</Link>
  </Layout>
);
